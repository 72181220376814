import { Form, Input, Button } from 'antd';
import { phoneReg } from 'egenie-common';
import { observer } from 'mobx-react';
import React from 'react';
import { TopBar } from '../components';
import { store } from '../login';
import styles from './index.less';

const { Item } = Form;

const FORM_LAY_OUT = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const Register: React.FC = observer(() => {
  const { registerRef, register } = store;
  return (
    <div className={styles.page}>
      <TopBar/>
      <div className={styles.registerWrapper}>
        <div className={styles.register}>
          <Form
            ref={registerRef}
            {...FORM_LAY_OUT}
            style={{
              width: '398px',
              margin: 'auto',
            }}
          >
            <div
              className={styles.title}
              style={{ marginBottom: '32px' }}
            >
              欢迎注册衫海精选款平台
            </div>
            <Item
              label="手机号"
              name="mobile"
              rules={[
                { required: true },
                {
                  pattern: phoneReg,
                  message: '请输入正确的手机号',
                },
              ]}
            >
              <Input placeholder="请输入11位手机号"/>
            </Item>
            <Item
              label="验证码"
              required
            >
              <div className={styles.spaceBetweenLayout}>
                <Item
                  name="smsCode"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: '请输入验证码',
                    },
                  ]}
                >
                  <Input
                    className={styles.checkCodeInput}
                    placeholder="请输入验证码"
                  />
                </Item>
                <ValidateButton/>
              </div>
            </Item>
            <Item
              label="密码"
              name="password"
              required
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject('请输入密码');
                    }
                    if (value.length < 8 || value.length > 16) {
                      return Promise.reject('密码长度为8～16位');
                    }

                    if (!/[A-Za-z]/.test(value) || !/\d/.test(value)) {
                      return Promise.reject('密码需要包含字母和数字');
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password placeholder="密码需要包含字母和数字，且长度为8-16位"/>
            </Item>
            <Item
              dependencies={['password']}
              label="确认密码"
              name="checkPassword"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('两次输入密码不一样'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="确认密码"/>
            </Item>
            <Item
              label="邀请码"
              name="inviteCode"
            >
              <Input placeholder="邀请码(选填）"/>
            </Item>
            <Item
              colon={false}
              label=" "
            >
              <div
                className={styles.link}
                onClick={() => {
                  store.toggleLoginFlag(true);
                }}
              >
                已有账号，返回登录
                {'>>'}
              </div>
            </Item>
          </Form>
          <Button
            className={styles.buttonStyle}
            onClick={register}
            type="primary"
          >
            注册
          </Button>
        </div>
      </div>
    </div>
  );
});

const ValidateButton: React.FC = observer(() => {
  const { showCountDown, countSeconds, getValidateCode } = store;
  return (
    <Button
      className={styles.checkButton}
      disabled={showCountDown}
      onClick={getValidateCode}
      type="primary"
    >
      {showCountDown ? `${countSeconds}后重新获取` : '获取验证码'}
    </Button>
  );
});

export default Register;
